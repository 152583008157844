﻿<template>
  <div>
    <Headline
      title="Viaje por viaje"
      subtitle="Concéntrese en el objetivo de su viaje.<br>¡Le aseguramos el éxito!"
      image="index"
      variant="featured"
    >
      <Button @click="goToContent" variant="tertiary" size="lg">Viaja con nosotros</Button>
    </Headline>
    <Section
      id="quienes-somos"
      title="Quiénes somos"
      image="index-1"
    >
      <Card
        title="#1 Wamos - Grupo Turístico Nº1 en España e Portugal"
      ></Card>
      <Card
        title="+50 años mejorando procesos de gestión de viajes de empresas"
      ></Card>
      <Card
        title="96% de retención de clientes"
      ></Card>
      <Card
        title="24/7 servicio al cliente"
      ></Card>
      <Card
        title="+20 años de certificación de calidad"
      ></Card>
      <Card
        title="Representante exclusivo One Global Travel para España e Portugal"
        href="https://oneglobaltravel.com/"
      ></Card>
    </Section>
    <Section
      title="Nuestros servicios"
      image="index-2"
    >
      <!-- <Card
        title="Vacacional"
        image="index-1"
        href="https://topatlantico.es/"
      ></Card> -->
      <Card
        title="Viajes corporativos"
        image="index-4"
        to="/servicios/viajes-corporativos"
      ></Card>
      <Card
        title="Reuniones y Eventos"
        image="index-2"
        to="/servicios/reuniones-y-eventos"
      ></Card>
      <Card
        title="Viajes de grupos e Incentivos"
        image="index-3"
        to="/servicios/grupos-e-incentivos"
      ></Card>
    </Section>
    <Section
      title="Productos"
      image="index-3"
      variant="dark"
    >
      <Card
        title="Reservas online"
        image="index-5"
        to="/productos/reservas-online"
      ></Card>
      <Card
        title="Report"
        image="index-6"
        to="/productos/report"
      ></Card>
      <Card
        title="Facturación electrónica"
        image="index-7"
        to="/productos/facturacion-electronica"
      ></Card>
      <Card
        title="Integración de sistemas"
        image="index-8"
        to="/productos/integracion-de-sistemas"
      ></Card>
    </Section>
    <div class="grid grid--no-gap">
      <!-- <iframe class="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d903.165035521827!2d-3.6728377602273006!3d40.41166497255316!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd4227395a8b983f%3A0x61d2d6b5aa31a7ff!2sGeoStar!5e0!3m2!1sen!2spt!4v1693503417847!5m2!1sen!2spt" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> -->
      <ContactForm />
    </div>
  </div>
</template>
<script setup>
definePageMeta({
  title: 'Conócenos',
})
const title = useRoute().meta.title
useHead({
  title: `${title} | Geotur`,
})

const goToContent = () => {
  document.getElementById('quienes-somos').scrollIntoView({
    behavior: 'smooth',
  })
}
</script>
<style scoped>
.map {
  width: 100%;
  min-height: 30rem;
}
</style>